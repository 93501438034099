import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from "@angular/material/table";
import {Clipboard} from '@angular/cdk/clipboard';
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {EditCreditsComponent} from "../../shared/modals/edit-credits/edit-credits.component";
import {UserService} from "../services/user.service";
import {BlockUserComponent} from "../../shared/modals/block-user/block-user.component";
import {Observable} from "rxjs";
import {ResetLeaderboardComponent} from "../../shared/modals/reset-leaderboard/reset-leaderboard.component";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  displayedColumns: string[] = ['rank', 'email', 'username', 'wallet', 'engaged', 'lost', 'rate', 'credits', 'settings', 'block'];
  dataSource = new MatTableDataSource();
  users: any;
  paginatorLength: number = 0
  paginatorCurrentPage: number = 0;
  paginatorItemsPerPage: number = 0;
  username: string = ''

  constructor(
    private clipboard: Clipboard,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private userService: UserService,
  ) {
  }

  ngOnInit(): void {
    this.userService.refreshNeeded$.subscribe(() => {
      this.getUsers();
    });
    this.getUsers();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  openDialog(data: any) {

    this.dialog.open(EditCreditsComponent, {
      data: data,
      panelClass: 'custom-dialog-container'
    });
  }

  openBlockDialog(data: any)  {
      this.dialog.open(BlockUserComponent, {
        data: data,
        panelClass: 'custom-dialog-container'
      });
  }

  openResetLeaderBoardDialog()  {
    this.dialog.open(ResetLeaderboardComponent, {
      panelClass: 'custom-dialog-container'
    });
  }

  // Disable autoToggle
  checkBefore: Observable<boolean> = new Observable();

  getUsers() {
    this.userService.getUser(this.paginatorCurrentPage, this.username).subscribe(userList => {
      if (userList && userList.items) {
        this.paginatorLength = userList.meta.totalItems;
        this.users = userList.items;
        this.dataSource = this.users;
        if (this.dataSource && this.dataSource.data) {
          this.dataSource.paginator = this.paginator;
        }
      } else {
        return
      }
    })
  }

  pageChanged(event: PageEvent) {
    this.paginatorLength = event.pageSize;
    this.paginatorCurrentPage = event.pageIndex;
    this.getUsers();
  }

  copyWalletId(value: string) {
    this.clipboard.copy(value);
    this._snackBar.open('Copied', 'Close', {duration: 1500});
  }

  applyFilter(event: Event) {
    this.paginatorCurrentPage = 0
    const filterValue = (event.target as HTMLInputElement).value;
    this.username = filterValue
    this.getUsers()
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
