<div class="wrapper">
<h2 mat-dialog-title>{{this.data.isBanned? 'Unlock ' : 'Block ' }} the user </h2>
<mat-dialog-content class="mat-typography">
  <div class="title">Do you want to {{this.data.isBanned? 'unlock ' : 'block ' }} the user <span class="username">{{this.data.username}}</span>?</div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="buttons-wrapper" mat-dialog-actions>
    <button class="close-button" color="primary" mat-stroked-button (click)="blockUser()">Yes</button>
    <button class="save-button" mat-flat-button  [mat-dialog-close]="true">No</button>
  </div>
</mat-dialog-actions>
</div>
