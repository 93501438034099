<div class="login-box-wrapper">
  <div class="login-box">
    <div class="login-title">
      <div class="login-title-icon">
      </div>
      <div class="login-title-description">Log in to Admin panel</div>
    </div>

    <div class="login-fields-wrapper">
      <form (ngSubmit)="onSubmit()" class="login-fields" name="loginForm" [formGroup]="loginForm">
        <mat-error *ngIf="isValid === false">Your email or password you entered is incorrect</mat-error>
        <mat-form-field [class.mat-form-field-invalid]="isValid === false" class="email-control" appearance="fill">
          <mat-label>Email Address</mat-label>
          <input
            type="email"
            matInput
            class="password-control"
            id="email"
            formControlName="email"
            required
            placeholder="user@example.com"
          />
        </mat-form-field>

        <mat-form-field [class.mat-form-field-invalid]="isValid === false" class="form-control" appearance="fill">
          <mat-label>Password</mat-label>
          <input
            id="password"
            formControlName="password"
            required
            matInput
            type="{{isShowPassword? 'text': 'password'}}"
          />
          <button  type="button" matSuffix mat-icon-button aria-label="Show Password" (click)="$event.stopPropagation()">
            <mat-icon (click)="showPassword()">{{isShowPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
          </button>
        </mat-form-field>

        <div class="login-button">
          <button type="submit" mat-raised-button color="primary">Sign in</button>
        </div>
      </form>
    </div>
  </div>
</div>
