import {Component, Inject, OnInit} from '@angular/core';
import {UserService} from "../../../pages/services/user.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-block-user',
  templateUrl: './block-user.component.html',
  styleUrls: ['./block-user.component.scss']
})
export class BlockUserComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private userService: UserService,
              public dialogRef: MatDialogRef<BlockUserComponent>
  ) { }

  ngOnInit(): void {
  }

  blockUser() {
    this.userService.blockUser(this.data.id, {isBlocked: !this.data.isBanned}).subscribe(res => {
      if(res){
        this.dialogRef.close()
      }
    })
  }
}
