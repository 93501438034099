import { Component, OnInit } from '@angular/core';
import {Subscription} from "rxjs";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {AuthService} from "../services/auth/auth.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  isShowPassword: boolean = false;
  isValid: boolean | undefined;
  private subs: Subscription= new Subscription();

  constructor(private fb: FormBuilder,private router: Router, private authService: AuthService) {
  }

  ngOnInit(): void {
  }

  loginForm: FormGroup = this.fb.group({
    email: new FormControl('', [
      Validators.required,
      Validators.email,
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
    ]),
  });

  public showPassword() {
    this.isShowPassword = !this.isShowPassword;
  }

  public onSubmit() {
    const signInData = this.loginForm.value;
    if (!this.loginForm.valid) {
      return;
    } else if (signInData.email && signInData.password ){
      this.subs.add(this.authService.signIn(this.loginForm.value).subscribe(res => {
        this.isValid = res;
        if(this.isValid) {
          this.router.navigate(['/main'])
        } else {
          this.isValid = false;
        }
      },error => {
        console.log(error.message)
      }));
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
