import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from "rxjs/operators";
import {AuthService} from "../services/auth/auth.service";
import {Router} from "@angular/router";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  refreshExpiredAt: Date = new Date(Number(this.authService.getRefreshTokenExpiredTime()));
  accessExpiredAt: Date = new Date(Number(this.authService.getAccessTokenExpiredTime()));

  constructor(private authService: AuthService, private router: Router) {
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 401 || err.status === 403 || (new Date().getTime() == (this.accessExpiredAt.getTime() - 60000))) {
      this.authService.refreshToken().subscribe(() => location.reload());
    }

    if (new Date() < this.refreshExpiredAt ) {
      this.authService.logout();
      this.router.navigate(['/'])
    }
    return throwError(err);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authService.getAccessToken()) {
      request = AuthInterceptor.addToken(request, this.authService.getAccessToken())
    }
    return next.handle(request).pipe(catchError(err => this.handleAuthError((err))));
  }

  private static addToken(requset: HttpRequest<any>, accessToken: string | null) {
    return requset.clone({
      setHeaders: {
        'Authorization': `Bearer ${accessToken}`
      }
    })
  }
}
