<div class="wrapper">
  <h2 mat-dialog-title>Reset Leaderboard  </h2>
  <mat-dialog-content class="mat-typography">
    <div class="title">Do you want to reset current leaderboard ?</div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="buttons-wrapper" mat-dialog-actions>
      <button class="close-button" color="primary" mat-stroked-button (click)="resetLeaderboard()">Yes</button>
      <button class="save-button" mat-flat-button  [mat-dialog-close]="true">No</button>
    </div>
  </mat-dialog-actions>
</div>
