import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LoginComponent} from "./core/login/login.component";
import {UsersComponent} from "./pages/users/users.component";
import {AuthGuard} from "./core/guards/auth.guard";

const routes: Routes = [
  {
    path: "main",
    component: UsersComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '',
    component: LoginComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
