<div class="wrapper">
<h1 mat-dialog-title>Edit credits</h1>
<div mat-dialog-content>
  <div class="email">
    <span class="title-name">E-mail:</span>
    <span class="info">{{data.email}}</span>
  </div>
  <div class="user-name">
    <span class="title-name">Username:</span>
    <span class="info">{{data.username}}</span>
  </div>
  <div class="search-wrapper">
    <mat-form-field class="search" appearance="fill" color="primary">
      <input   matInput type="number" min="0" max="100000000000000000000" #input [(ngModel)]="credits">
    </mat-form-field>
  </div>
</div>
<div class="buttons-wrapper" mat-dialog-actions>
  <button class="close-button" color="primary" mat-stroked-button [mat-dialog-close]="true">Close</button>
  <button class="save-button" mat-flat-button (click)="editCredits()">Save</button>
</div>
</div>
