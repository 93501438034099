import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UserService} from "../../../pages/services/user.service";

@Component({
  selector: 'app-edit-credits',
  templateUrl: './edit-credits.component.html',
  styleUrls: ['./edit-credits.component.scss']
})
export class EditCreditsComponent implements OnInit {
  credits: number = 0

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    public dialogRef: MatDialogRef<EditCreditsComponent>,
  ) {
  }

  ngOnInit(): void {
    this.credits = this.data.credits
  }

  editCredits() {
    if (this.credits > 0 && this.credits < 100000000000000000000)
    this.userService.editCredits(this.data.id, {credits: this.credits}).subscribe(res => {
      if(res) {
        this.dialogRef.close();
      }
    });
  }

}
