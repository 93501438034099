import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {environment} from "../../../../environments/environment";
import {catchError, mapTo, tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private loggedUser: string | undefined;
  private readonly ACCESS_TOKEN = 'ACCESS_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  private readonly ACCESS_TOKEN_EXPIREDAT = 'ACCESS_TOKEN_EXPIREDAT';
  private readonly REFRESH_TOKEN_EXPIREDAT = 'REFRESH_TOKEN_EXPIREDAT';

  constructor(private http: HttpClient) { }

  public signIn(user: { email: string, password: string }): Observable<any> {
    return this.http.post(`${environment.apiUrl}/auth/sign-in`, user)
      .pipe(
        tap(tokens => {
          this.setSession(user.email, tokens);
        }),
        mapTo(true),
        catchError(error => {
          return of(false)
        })
      )
  }


  logout() {
    this.loggedUser = undefined;
    this.removeTokens();
  }

  isSignedIn() {
    return !!this.getAccessToken();
  }

  private setSession(userEmail: string, tokens: any) {
    this.loggedUser = userEmail;
    this.storeTokens(tokens);
  }

  private removeTokens() {
    localStorage.removeItem(this.ACCESS_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
    localStorage.removeItem(this.ACCESS_TOKEN_EXPIREDAT);
    localStorage.removeItem(this.REFRESH_TOKEN_EXPIREDAT);
  }

  private storeTokens(tokens: any) {
    localStorage.setItem(this.ACCESS_TOKEN, tokens.accessToken);
    localStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken);
    localStorage.setItem(this.ACCESS_TOKEN_EXPIREDAT, tokens.accessExpiresIn);
    localStorage.setItem(this.REFRESH_TOKEN_EXPIREDAT, tokens.refreshExpiresIn);
  }

  public getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  public getRefreshTokenExpiredTime() {
    return localStorage.getItem(this.REFRESH_TOKEN_EXPIREDAT);
  }

  public getAccessTokenExpiredTime() {
    return localStorage.getItem(this.ACCESS_TOKEN_EXPIREDAT);
  }

  public refreshToken() {
    return this.http.post(`${environment.apiUrl}/auth/refresh?refreshToken=${this.getRefreshToken()}`, {})
      .pipe(tap((tokens: any) => {
        this.storeAccessToken(tokens);
      }));
  }

  private storeAccessToken(jwt: any) {
    localStorage.setItem(this.ACCESS_TOKEN, jwt.accessToken);
    localStorage.setItem(this.REFRESH_TOKEN, jwt.refreshToken);
  }

  public getAccessToken() {
    return localStorage.getItem(this.ACCESS_TOKEN);
  }
}
