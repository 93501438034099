import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './core/login/login.component';
import { UsersComponent } from './pages/users/users.component';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatTableModule} from "@angular/material/table";
import { HeaderComponent } from './core/header/header.component';
import {UiSwitchModule} from "ngx-ui-switch";
import {MatPaginatorModule} from "@angular/material/paginator";
import {ClipboardModule} from "@angular/cdk/clipboard";
import { BlockUserComponent } from './shared/modals/block-user/block-user.component';
import { EditCreditsComponent } from './shared/modals/edit-credits/edit-credits.component';
import { MatSnackBarModule} from "@angular/material/snack-bar";
import {MatDialogModule} from "@angular/material/dialog";
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthInterceptor} from "./core/interceptors/auth.interceptor";
import {AuthGuard} from "./core/guards/auth.guard";
import {AuthService} from "./core/services/auth/auth.service";
import { ResetLeaderboardComponent } from './shared/modals/reset-leaderboard/reset-leaderboard.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    UsersComponent,
    HeaderComponent,
    BlockUserComponent,
    EditCreditsComponent,
    ResetLeaderboardComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatIconModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatTableModule,
    UiSwitchModule,
    MatPaginatorModule,
    ClipboardModule,
    MatSnackBarModule,
    MatDialogModule,
    HttpClientModule,
  ],
  providers: [AuthService, AuthGuard,MatSnackBarModule, {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true,
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
