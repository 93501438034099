import {Injectable} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _refreshNeeded$ = new Subject<void>();

  constructor(private http: HttpClient) {
  }

  get refreshNeeded$() {
    return this._refreshNeeded$;
  }

  public getUser(pageNumber: number, username?: string): Observable<any> {
    if (username) {
      return this.http.get<any>(`${environment.apiUrl}/users?page=${pageNumber}&username=${username}`);
    } else {
      return this.http.get<any>(`${environment.apiUrl}/users?page=${pageNumber}`);
    }
  }

  public editCredits(id: string, credits: { credits: number}) {
    return this.http.post<any>(`${environment.apiUrl}/users/${id}/credits`, credits)
      .pipe(
        tap(() => {
          this._refreshNeeded$.next();
        })
      );
  }

  public resetLeaderBoard() {
    return this.http.post<any>(`${environment.apiUrl}/leaderboard/reset`, {})
      .pipe(
        tap(() => {
          this._refreshNeeded$.next();
        })
      );
  }


  public blockUser(id: string, status: { isBlocked: boolean}) {
    return this.http.post<any>(`${environment.apiUrl}/users/${id}/toggle-block`, status)
      .pipe(
        tap(() => {
          this._refreshNeeded$.next();
        })
      );
  }
}
