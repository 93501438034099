<app-header></app-header>

<div class="users-wrapper">
  <div class="users-table">

    <div class="search-wrapper">
      <mat-icon class="search-icon">search</mat-icon>
      <input class="input" type="text" (keyup)="applyFilter($event)" placeholder="Search by username" #input>
      <div class="reset-button-wrapper">
        <button (click)="openResetLeaderBoardDialog()" mat-stroked-button color="primary">RESET LEADERBOARD</button>
      </div>
    </div>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

      <!-- Position Column -->
      <ng-container matColumnDef="rank">
        <th mat-header-cell *matHeaderCellDef>Rank</th>
        <td mat-cell
            *matCellDef="let i = index">  {{this.paginator.pageIndex == 0 ? i + 1 : 1 + i + this.paginator.pageIndex * this.paginator.pageSize}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>E-mail</th>
        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef>Username</th>
        <td mat-cell *matCellDef="let element"> {{element.username}} </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="wallet">
        <th mat-header-cell *matHeaderCellDef>Wallet id</th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.walletAddress" class="truncateLongTexts" (click)="copyWalletId(element.walletAddress)">
            <mat-icon>content_copy</mat-icon>
            <div class="wallet">{{element.walletAddress}}</div>
          </div>
          <!--          <div *ngIf="!element.walletAddress">—</div>-->
        </td>
      </ng-container>

      <!-- Position Column -->
      <ng-container matColumnDef="engaged">
        <th mat-header-cell *matHeaderCellDef>Number of game engaged</th>
        <td mat-cell *matCellDef="let element"> {{element.gamesEngaged}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="lost">
        <th mat-header-cell *matHeaderCellDef>Number of game lost</th>
        <td mat-cell *matCellDef="let element"> {{element.gamesLost}} </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="rate">
        <th mat-header-cell *matHeaderCellDef>Winning rate %</th>
        <td mat-cell *matCellDef="let element"> {{element.winRate}} </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="credits">
        <th mat-header-cell *matHeaderCellDef>Credits</th>
        <td mat-cell *matCellDef="let element">  {{element.credits}} </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="settings">
        <th style="width: 40px" mat-header-cell *matHeaderCellDef></th>
        <td  mat-cell *matCellDef="let element" >
          <mat-icon *ngIf="!element.isBanned" (click)="openDialog(element)" class="edit-button">edit</mat-icon>
        </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="block">
        <th mat-header-cell *matHeaderCellDef>Block</th>
        <td mat-cell *matCellDef="let element">
          <ui-switch
            style="cursor: pointer"
            switchOffColor="#643EFF"
            color="#F0EDFC"
            defaultBgColor="#F0EDFC"
            size="small"
            [beforeChange]="checkBefore"
            [reverse]="true"
            [checked]="element.isBanned"
            (click)="openBlockDialog(element)">
          </ui-switch>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr [class.blockedRow]="row?.isBanned"  mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
      </tr>
    </table>
    <mat-paginator (page)="pageChanged($event)" [pageIndex]="paginatorCurrentPage" [length]="paginatorLength" [pageSize]="10" [hidePageSize]="true"
                   pageSize="12"></mat-paginator>
  </div>
</div>
