import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UserService} from "../../../pages/services/user.service";

@Component({
  selector: 'app-reset-leaderboard',
  templateUrl: './reset-leaderboard.component.html',
  styleUrls: ['./reset-leaderboard.component.scss']
})
export class ResetLeaderboardComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<ResetLeaderboardComponent>,
              private userService: UserService
  ) { }

  ngOnInit(): void {
  }


  resetLeaderboard(){
    this.userService.resetLeaderBoard().subscribe(res => {
      if(res){
        this.dialogRef.close()
      }
    })
  }

}
